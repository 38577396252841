import './ResetPassword.css';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPassword() {

    return (
        <div className="Reset-password-page-component">
            <ResetPasswordForm />
        </div>
    );
}

export default ResetPassword;
