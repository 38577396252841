import axios from "../UnauthorizedAxiosResponse";
import Constants from "../../components/Constants";

const TrainerService = {

    createTraining: async (formData) => {
        try {
            const response = await axios.post(`${Constants.BASE_URL}/trainer/create-training`, formData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status);
        }
    },

    getRejectedTrainingByExponentId: async (requestData) => {
        try {
            const response = await axios.post(`${Constants.BASE_URL}/trainer/rejected-trainings-by-exponent-id`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status);
        }
    },

    updateTrainingByTrainingId: async (formData) => {
        try {
            const response = await axios.post(`${Constants.BASE_URL}/trainer/update-training-by-training-id`, formData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status);
        }
    },

    getApprovedTrainingByExponentId: async (requestData) => {
        try {
            const response = await axios.post(`${Constants.BASE_URL}/trainer/approved-trainings-by-exponent-id`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status);
        }
    },

    getStartedTrainingByExponentId: async (requestData) => {
        try {
            const response = await axios.post(`${Constants.BASE_URL}/trainer/started-trainings-by-exponent-id`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status);
        }
    },

    getCompletedTrainingByExponentId: async (requestData) => {
        try {
            const response = await axios.post(`${Constants.BASE_URL}/trainer/completed-trainings-by-exponent-id`, requestData, {
                headers: {
                    'Authorization' : `Bearer ${localStorage.getItem('breezeJwtToken')}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error.response.data.status);
        }
    },

}

export default TrainerService;