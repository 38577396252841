import './Register.css';
import RegisterForm from './RegisterForm.js';

function Register() {

  return (
    <div className="Register-page-component">
      <RegisterForm />
    </div>
  );

}

export default Register;
