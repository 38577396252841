import './Login.css';
import LoginForm from './LoginForm';

function Login() {

  return (
    <div className="Login-page-component">
      <LoginForm />
    </div> 
  );

}
  
export default Login;