import './VerifyEmail.css';
import VerifyEmailForm from "./VerifyEmailForm";

function VerifyEmail() {

    return(
        <div className="Verify-email-page-component">
            <VerifyEmailForm />
        </div>
    );

}

export default VerifyEmail;